import React, { Component } from 'react';
import Slider from 'react-slick';

import RightNav from '../../components/RightNavButton';
import LeftNav from '../../components/LeftNavButton';
import ModalsDefault from '../../components/Modals/Default/';
import i18n from '../../assets/i18n';

class Filosofia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      component: '',
      title: i18n.t('filosofia_title_modal'),
      desc: i18n.t('filosofia_desc_modal')
    };

    this.open = this.open.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  open() {
    var ModalType = <ModalsDefault title={this.state.title} desc={this.state.desc} closeModal={this.closeModal} />;
    this.setState({modal: true, component: ModalType});
  }

  closeModal(){
    this.setState({modal: false, component: ''});
  }

  render() {

    var settings = {
      dots: false,
      infinite: true,
      nextArrow: <RightNav />,
      prevArrow: <LeftNav />,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div className="filosofia-section animated fadeIn">
        <div className="filosofia-content-sx">
          <Slider {...settings}>
            <div className="slide" id="slide-2-1" />
            <div className="slide" id="slide-2-2" />
          </Slider>
        </div>
        <div className="filosofia-content-dx">
          <div className="vertical-content">
            <h5>{i18n.t('filosofia_subtitle')}</h5>
            <h2>"{i18n.t('filosofia_title')}"</h2>
            <button type="button" className="btn" onClick={this.open}>
              {i18n.t('leggi_title')}
            </button>
          </div>
          {(this.state.modal) ? this.state.component : ''}
        </div>
      </div>
    )
  }
}

export default Filosofia;