import React, { Component } from 'react';
import Slider from 'react-slick';

import RightNav from '../../components/RightNavButton';
import LeftNav from '../../components/LeftNavButton';
import ModalsServices from '../../components/Modals/Services/';

import i18n from '../../assets/i18n';
import $ from 'jquery';

class Servizi extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      component: ''
    };

    this.click = this.click.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  click(target) {
    var ModalType = <ModalsServices type={target} closeModal={this.closeModal} />;
    this.setState({modal: true, component: ModalType});
  }

  closeModal(){
    this.setState({modal: false, component: ''});
  }

  prenota() {
    $('html, body').stop().animate({scrollTop: $('.contatti-section').offset().top}, 1000);
  }

  render() {

    var settings = {
      dots: false,
      infinite: true,
      nextArrow: <RightNav />,
      prevArrow: <LeftNav />,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div className="servizi-section animated fadeIn">
        <div className="servizi-content-sx">
          <Slider {...settings}>
            <div className="slide" id="slide-5-1" />
            <div className="slide" id="slide-5-2" />
            <div className="slide" id="slide-5-3" />
          </Slider>
        </div>
        <div className="servizi-content-dx">
          <div className="top-content">
            <h4>{i18n.t('servizi_title')}</h4>

            {(this.state.modal) ? this.state.component : ''}

            <div className="services-list" style={{ overflow: 'hidden' }}>
              <div className="services-1">
                <button type="button" className="btn nostyle" onClick={() => this.click('serv_1')}>
                  <h6>{i18n.t('servizio_1_title')}</h6><i className="icon-arrow-dx"></i>
                </button>
              </div>
              <div className="services-2">
                <button type="button" className="btn nostyle" onClick={() => this.click('serv_2')}>
                  <h6>{i18n.t('servizio_2_title')}</h6><i className="icon-arrow-dx"></i>
                </button>
              </div>
              <div className="services-3">
                <button type="button" className="btn nostyle" onClick={() => this.click('serv_3')}>
                  <h6>{i18n.t('servizio_3_title')}</h6><i className="icon-arrow-dx"></i>
                </button>
              </div>
              <div className="services-4">
                <button type="button" className="btn nostyle" onClick={() => this.click('serv_4')}>
                  <h6>{i18n.t('servizio_4_title')}</h6><i className="icon-arrow-dx"></i>
                </button>
              </div>
            </div>
            <div className="content-footer">
              <button type="button" className="btn trasp prenota" onClick={this.prenota}>
                {i18n.t('servizi_prenota')}
              </button>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default Servizi;
