import React, { Component, PropTypes } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import i18n from '../../../assets/i18n';
import $ from 'jquery';

class Color extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            form: {
                type: ''
            }
        };

        this.close = this.close.bind(this);
        this.cancel = this.cancel.bind(this);

    }

    close() {
        this.setState({
            show: false
        });

        this.props.closeModal();
    }

    cancel() {
        this.close();
    }

    componentDidMount(){
        this.setState({
            show: true,
            type: this.props.type
        });
    }

    render() {

        return (
            <div className="animated fadeIn">
                <Modal isOpen={this.state.show} toggle={this.close} className={'modal-lg modal-default'}>
                    <ModalHeader toggle={this.close}></ModalHeader>
                    <ModalBody>

                        <img src="/img/collezioni/head-color.jpg" style={{ marginBottom: 30 }} />

                        <h3>BROADWAY COLOR</h3>

                        <p>New York, 1979. Jheri Redding, scienziato e cosmetologo, ha un’intuizione rivoluzionaria. Trattare i capelli con lo stesso elemento di cui sono fatti: le proteine. Dalla formula dei suoi prodotti, che infondono nuova vita ai capelli, nasce una lunga storia di successo - Nexxus - che passa per i più famosi Salon newyorkesi.<br/>
                            Ed è proprio a New York che ci siamo ispirati per creare una nuova linea professionale di colori intensi, vibranti e glamour come la più scintillante delle vie: Broadway, con le luci ipnotiche dei suoi teatri.</p>


                        <div className="ColorColumns">
                            <div>

                                <h4 style={{ margin: 0, marginBottom: 10 }}>SUNBOW</h4>
                                <h5>COLORAZIONE PERMANENTE PER CAPELLI</h5>

                                <p>Tante nuance e una formula unica, per la copertura
                                dei capelli bianchi e un colore intenso di lunga durata. Una linea completa per ogni esigenza professionale
                                    che dona ai capelli lucentezza e un effetto condizionante.</p>

                                <p>Come tutte le nostre linee, anche la gamma Nexxus Broadway Color è arricchita con proteine di origine vegetale, le PROTEINE DELLA SOIA.</p>
                                <p>A queste, abbiamo aggiunto le proprietà
                                di un superfood come le BACCHE DI GOJI.
                                    Infine, gli ATTIVATORI NEXXUS sono formulati con agenti emollienti che aiutano a proteggere cute e capelli durante il procedimento di colorazione e schiaritura, per una formula dall’alta tollerabilità.</p>
                                <p>SOLO PER USO PROFESSIONALE</p>

                                <p style={{marginTop: 10}}><a target="_blank" href="/pdf/Color-1.pdf" className="btn">Scopri la linea</a></p>

                            </div>
                            <div>

                                <h4 style={{margin: 0, marginBottom: 10}}>BON TONE</h4>
                                <h5>COLORAZIONE DEMI- PERMANENTE PER CAPELLI</h5>

                                <p>Tutta la luminosità delle luci di New York, in una linea professionale per tonalizzare, scurire e/o riflessare i capelli.</p>
                                <p>Shades selezionate, dalla formula unica e ben calibrata, ideali per donare ai capelli vitalità e un colore tono su tono a lunga durata*.</p>
                                    <p>L’attivatore NEXXUS OXXY FLUID, grazie alla sua consistenza liquida rende il servizio di tonalizzazione semplice e veloce.</p>
                                    <p>Inoltre, la linea di colorazione demi-permanente Nexxus Broadway Color Bon-tone utilizzata con l’attivatore Nexxus Oxxy cream 10 vol è ideale per applicazioni con pennello e tecnica di sfumatura roots blend.</p>
                                    <p>CON PROTEINE DEL GRANO E BACCHE DI ACAI. SOLO PER USO PROFESSIONALE.</p>

                                <p style={{marginTop: 10}}><a target="_blank" href="/pdf/Color-2.pdf" className="btn">Scopri la linea</a></p>

                            </div>
                        </div>

                        <p>* Risultati ottenuti con test strumentali di color fade, pettinabilità e lucentezza . Novembre 2021, Italia.</p>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default Color;
