/**
 * Created by alex on 20/07/17.
 */

import json from './lang/lang.json';

var i18n = {
  lang : 'it',
  // lang : 'en',

  t : function(key, lang = this.lang) {
    return (json[lang][key]);
  },

  setLang(lang){
    this.lang = lang;
  }

};

export default i18n;
