import React, { Component, PropTypes } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactJWPlayer from 'react-jw-player';

class ModalsVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      form: {
        title: '',
        description: ''
      }
    };

    this.close = this.close.bind(this);
    this.cancel = this.cancel.bind(this);

  }

  close() {
    this.setState({
      show: false
    });

    this.props.closeModal();
  }

  cancel() {
    this.close();
  }

  componentDidMount(){
    this.setState({
      show: true
    });
  }

  render() {

    let repeat = this.props.data.repeat;
    let controls = this.props.data.controls;

    return (
      <div className="animated fadeIn">
        <Modal isOpen={this.state.show} toggle={this.close} className={'modal-lg modal-default modal-video'}>
          <div className="modal-container">
            <ModalHeader toggle={this.close}></ModalHeader>
            <ModalBody>
              <ReactJWPlayer
                playerId='player_container'
                playerScript='https://content.jwplatform.com/libraries/pgpRgwzE.js'
                file={this.props.data.file}
                image={this.props.data.image}
                isAutoPlay='true'
                aspectRatio='16:9'
                customProps={{ width: '100%', stretching: 'fill', repeat: {repeat}, controls: {controls} }}
              />
              <div id="player_container" align='center'></div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ModalsVideo;
