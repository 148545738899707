import React, { Component } from 'react';
import MenuMobile from './MenuMobile';

class Menu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      component: ''
    };

    this.close = this.close.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);

  }

  close(){
    this.mobileSidebarToggle();
  }

  closeMenu(){
    setTimeout(function() {
      this.setState({menu: false, component: ''});
    }.bind(this), 500);
  }

  mobileSidebarToggle() {
    document.body.classList.toggle('sidebar-mobile-show');

    var menuMobile = '';
    if(!this.state.menu) {
      menuMobile = <MenuMobile close={this.close} />;
      this.setState({menu: true, component: menuMobile});
    } else {
      this.closeMenu();
    }

  }

  componentWillMount(){
    this.setState({menu: false});
  }

  render() {
    return (
      <div>
        <div className="menu-trigger">
          <button className="navbar-toggler" onClick={this.mobileSidebarToggle} type="button"><i className="icon-menu left"></i><i className="icon-menu right"></i></button>
        </div>
        {(this.state.menu) ? this.state.component : ''}
      </div>
    )
  }
}

export default Menu;
