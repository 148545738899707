import React, { Component } from 'react';
import $ from 'jquery';

import i18n from '../../../assets/i18n';

class MenuMobile extends Component {

  constructor(props) {
    super(props);

    this.click = this.click.bind(this);
    this.setLang = this.setLang.bind(this);
  }

  click(target){
    $('html, body').stop().animate({scrollTop: $(target).offset().top}, 1000);
    this.props.close();
  }

  setLang(lang){
    switch (lang){
      case 'en':
        i18n.setLang('en');
        break;
      case 'it':
        i18n.setLang('it');
        break;
    }
    this.props.close();
    setTimeout(function() {
      window.location.reload();
    }, 200);
  }

  componentWillMount() {
    setTimeout(function() {
      document.body.classList.toggle('show-menu');
    }, 500);
  }

  componentWillUnmount() {
    setTimeout(function() {
      document.body.classList.toggle('show-menu');
    }, 0);
  }


  render() {
    return (
      <div className="menu-container">
        <div className="menu-container-wrapper">
          <div className="menu-header">
            <a href="#/it" onClick={() => this.setLang('it')} title="IT">IT</a>
            &nbsp;|&nbsp;
            <a href="#/en" onClick={() => this.setLang('en')} title="EN">EN</a>
          </div>
          <ul>
            <li><span onClick={() => this.click('.filosofia-section')}>{i18n.t('menu_filosofia')}</span></li>
            <li><span onClick={() => this.click('.luogo-section')}>{i18n.t('menu_salone')}</span></li>
            <li><span onClick={() => this.click('.video-content')}>{i18n.t('menu_video')}</span></li>
            <li><span onClick={() => this.click('.servizi-section')}>{i18n.t('menu_servizi')}</span></li>
            <li><span onClick={() => this.click('.collezioni-section')}>{i18n.t('menu_prodotti')}</span></li>
            <li><span onClick={() => this.click('.contatti-section')}>{i18n.t('menu_contatti')}</span></li>
          </ul>
          <div className="menu-footer">
            <span>{i18n.t('seguici_title')}:</span>
            <a href="https://www.facebook.com/TigiConceptVerona/" title="TIGIConcept" target="_blank">Facebook</a>
            &nbsp;|&nbsp;<a href="https://www.instagram.com/tigiconcept_salon/" title="TIGIConcept" target="_blank">Instagram</a>
          </div>
        </div>
      </div>
    )
  }
}

export default MenuMobile;
