import React, { Component, PropTypes } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalsDefault extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      form: {
        title: '',
        description: ''
      }
    };

    this.close = this.close.bind(this);
    this.cancel = this.cancel.bind(this);

  }

  close() {
    this.setState({
      show: false
    });

    this.props.closeModal();
  }

  cancel() {
    this.close();
  }

  componentDidMount(){
    this.setState({
      show: true
    });
  }

  render() {

    var img = "";
    if(typeof this.props.img !== "undefined") {
      var img = "<img src='" + this.props.img + "' />";
    }

    return (
      <div className="animated fadeIn">
        <Modal isOpen={this.state.show} toggle={this.close} className={'modal-lg modal-default'}>
          <ModalHeader toggle={this.close}></ModalHeader>
          <ModalBody>
            <h4>{this.props.title}</h4>
            <div dangerouslySetInnerHTML={{__html: this.props.desc}}></div>
            <div dangerouslySetInnerHTML={{__html: img}}></div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default ModalsDefault;
