import React, { Component } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import Header from '../../components/Header/';
import Menu from '../../components/Menu/';
import Footer from '../../components/Footer/';

import Slide from '../../views/Slide/';
import Filosofia from '../../views/Filosofia/';
import Luogo from '../../views/Luogo/';
import Corso from '../../views/Corso/';
import Salone from '../../views/Salone/';
import Servizi from '../../views/Servizi/';
import Collezioni from '../../views/Collezioni/';
import Prodotti from '../../views/Prodotti/';
import Team from '../../views/Team/';
import Instagram from '../../views/Instagram/';
import Maps from '../../views/Maps/';
import Contatti from '../../views/Contatti/';

import i18n from '../../assets/i18n';

class Full extends Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    switch (this.props.location.pathname){
      case '/en':
        i18n.setLang('en');
        break;
      case '/it':
        i18n.setLang('it');
        break;
    }
  }

  render() {
    return (
      <div className="app">
        <Header />
        <Menu />
        <div className="app-body">
          <main className="main">
            <Slide />
            <Filosofia />
            <Luogo />
            <Corso />
            <Salone />
            <Servizi />
            <Collezioni />
            <Team />
            {/*<Instagram />*/}
            <Maps />
            <Contatti />
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Full;
