import React, { Component } from 'react';
import ModalsDefault from '../../components/Modals/Default/';
import i18n from '../../assets/i18n';

import Lightbox from '../../components/Lightbox';
import Care from "../../components/Modals/Products/Care";
import Color from "../../components/Modals/Products/Color";

class Collezioni extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      component: ''
    };

    this.open = this.open.bind(this);
    this.closeModal = this.closeModal.bind(this);

    /*this.state = {
      showLightbox : false,
      component : ''
    };

    this.closeLightbox = this.closeLightbox.bind(this);*/
  }

  open(target) {

    var title = '';
    var desc = '';
    var img = '';

    switch (target){
      case 'color':
        title = i18n.t('collezione_title_copy');
        desc = i18n.t('collezione_desc_copy') + '<br />' + i18n.t('collezione_desc_care');
        img = 'img/copy-color.jpg';
        break;
      case 'care':
        title = i18n.t('collezione_title_care');
        desc = i18n.t('collezione_desc_care') + '<br><br>' + i18n.t('collezione_desc_copy');
        img = 'img/copy-care.jpg';
        break;
    }

    var ModalType = <ModalsDefault title={title} desc={desc} img={img} closeModal={this.closeModal} />;
    this.setState({modal: true, component: target});
  }

  closeModal(){
    this.setState({modal: false, component: ''});
  }

  /*openLightbox(target){

    var elem = <Lightbox target={target} close={this.closeLightbox} />;
    this.setState({showLightbox: true, component: elem});

  }

  closeLightbox(){
    this.setState({showLightbox: false, component: ''});
  }*/

  render() {

    return (
      <div className="collezioni-section animated fadeIn">

          {(this.state.modal) ? (
              this.state.component === 'care' ? <Care closeModal={() => this.closeModal()} /> : <Color closeModal={() => this.closeModal()} />
          ) : ''}

        <div className="collezioni-content">
          <div className="collezioni-2" />
          <span onClick={() => this.open('care')}><img id="care" className="logo-img" src="./img/collezioni/logo-nexxus-1.png" /></span>
        </div>
        <div className="collezioni-content">
          <div className="collezioni-1" />
          <span onClick={() => this.open('color')}><img id="copy" className="logo-img" src="./img/collezioni/logo-nexxus-2.png" /></span>
        </div>
      </div>
    )
  }
}

export default Collezioni;
