import React, { Component } from 'react';
import Slider from 'react-slick';
import $ from 'jquery';

class Slide extends Component {

  constructor(props) {
    super(props);

    this.click = this.click.bind(this);

  }

  click() {
    $('html, body').stop().animate({scrollTop: $('.filosofia-section').offset().top}, 1000);
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      fade: true,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="slider-section slider-container">
        <div className="slide" id="slide-1-1" />
        <div className="slider-content">
          <img src="./img/logo.png" id="logo" />
          <h6>VERONA</h6>
        </div>
        <div className="slider-footer">
          <i className="icon-arrow-down" onClick={this.click}></i>
        </div>
      </div>
    )
  }
}

export default Slide;
