import React, { Component, PropTypes } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import i18n from '../../../assets/i18n';
import $ from 'jquery';

class Care extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            form: {
                type: ''
            }
        };

        this.close = this.close.bind(this);
        this.cancel = this.cancel.bind(this);

    }

    close() {
        this.setState({
            show: false
        });

        this.props.closeModal();
    }

    cancel() {
        this.close();
    }

    componentDidMount(){
        this.setState({
            show: true,
            type: this.props.type
        });
    }

    render() {

        return (
            <div className="animated fadeIn">
                <Modal isOpen={this.state.show} toggle={this.close} className={'modal-lg modal-default'}>
                    <ModalHeader toggle={this.close}></ModalHeader>
                    <ModalBody>

                        <img src="/img/collezioni/head-care.jpg" style={{ marginBottom: 30 }} />

                        <h3>LE NOSTRE ORIGINI</h3>
                        <p>New York, 1979.<br />
                        Jheri Redding, scienziato e cosmetologo, ha un’intuizione rivoluzionaria. Trattare i capelli con lo stesso elemento di cui sono fatti: le proteine.<br/>
                        Dalla formula dei suoi prodotti, che infondono nuova vita ai capelli, nasce una lunga storia di successo - NEXXUS - che passa per i più famosi Salon newyorkesi.<br/>
                            La lunga tradizione nel trattamento dei capelli con le proteine è ciò che rende speciale Nexxus.</p>

                        <h3>LA SCIENZA DELLE PROTEINE</h3>
                        <p>È proprio la lunga tradizione nel trattamento dei capelli con le proteine a rendere speciale Nexxus, che regala capelli splendidi perché va a nutrirli dall’interno.<br/>
                            Attraverso l’estrazione delle proteine più pure, il team di Science Insights di Nexxus è in prima linea negli studi che mappano il DNA dei capelli, per identificare quali ingredienti forniscono i migliori risultati per ogni tipologia capillare realizzando per ciascuna i migliori prodotti e trattamenti professionali.</p>

                        <h3>LA PROTEOMICA:<br/>
                            LO STUDIO DELLE PROTEINE</h3>
                        <p>Semplici azioni quotidiane, come lo styling termico e l’esposizione ai raggi UV, vanno a influire sulla chimica del capello.<br/>
                        La proteomica, semplicemente spiegata come lo studio delle proteine, è l’approccio all’avanguardia di Nexxus per riconoscere ciò di cui i capelli hanno bisogno, estraendo i migliori ingredienti dalla natura.<br/>
                            Così i capelli, trattati con le proteine, recuperano ciò che è stato perso e risultano splendidi.</p>

                        <h3>I NOSTRI INGREDIENTI</h3>
                        <p>In Nexxus sappiamo che i capelli sono composti per circa il 90% da proteine, motivo per cui usiamo metodi scientifici avanzati per identificare i bisogni di proteine di tutte le tipologie di capello.</p>

                        <h3>«IL SEGRETO DI NEXXUS:<br/>
                        I CAPELLI SONO COMPOSTI PER CIRCA IL 90% DA PROTEINE.<br/>
                        OGNI GAMMA NEXXUS È INFUSA<br/>
                            CON UNA MISCELA DI PROTEINE CHE AIUTANO A RIPRISTINARE I CORRETTI LIVELLI DI PROTEINE ALL’INTERNO DEI CAPELLI.»</h3>

                        <p style={{ marginTop: 20 }}><a target="_blank" href="/pdf/Care.pdf" className="btn">Scopri la linea</a></p>

                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default Care;
