import React, { Component } from "react";
import $ from "jquery";
import Recaptcha from "react-google-invisible-recaptcha";
import i18n from "../../assets/i18n";

let captcha;

class Contatti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      email: "",
      telefono: "",
      messaggio: "",
      privacy: "",
      mex: "",
      cls: ""
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeTel = this.handleChangeTel.bind(this);
    this.handleChangeMes = this.handleChangeMes.bind(this);
    this.handleChangePrivacy = this.handleChangePrivacy.bind(this);
    this.handleChangeCaptcha = this.handleChangeCaptcha.bind(this);
    this.sendMail = this.sendMail.bind(this);

    this.setOk = this.setOk.bind(this);
    this.setError = this.setError.bind(this);
    this.setErrorCaptcha = this.setErrorCaptcha.bind(this);
  }

  handleChangeName(e) {
    this.setState({ nome: e.target.value });
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  handleChangeTel(e) {
    this.setState({ telefono: e.target.value });
  }

  handleChangeMes(e) {
    this.setState({ messaggio: e.target.value });
  }

  handleChangePrivacy(e) {
    this.setState({ privacy: e.target.value });
  }

  setOk() {
    this.setState({ mex: "La mail è stata inviata correttamente!" });
    this.setState({ cls: "success" });
  }

  setError() {
    this.setState({
      mex: "Qualcosa non ha funzionato, si prega di riprovare."
    });
    this.setState({ cls: "error" });
  }

  setErrorCaptcha() {
    this.setState({
      mex:
        "Il sistema ha rilevato che la richiesta è stata eseguita da un BOT Automatico. Se leggi questo messaggio per errore, o ritieni di non essere un Robot, usa la mail soprastante per contattare il sito."
    });
    this.setState({ cls: "error" });
  }

  clearForm() {
    this.setState({
      nome: "",
      email: "",
      telefono: "",
      messaggio: "",
      privacy: ""
    });
  }

  handleChangeCaptcha() {
    var that = this;
    $.ajax({
      url: "https://www.tigiconceptvr.it/sendmail.php",
      type: "POST",
      data: {
        nome: this.state.nome,
        mail: this.state.email,
        telefono: this.state.telefono,
        messaggio: this.state.messaggio
      },
      success: function(data) {
        that.setOk();
        that.clearForm();
      },
      error: function(data) {
        that.setError();
        that.clearForm();
      }
    });
  }

  sendMail(e) {
    e.preventDefault();
    this.recaptcha.execute();
  }

  render() {
    return (
      <div className="contatti-section" id="contact">
        <div className="animated fadeIn">
          <h4 className="row-header">{i18n.t("contatti_title")}</h4>
          <div className="contatti-content">
            <div className="contatti-content-header">
              <p className="big">
                {i18n.t("contatti_chiama")}:{" "}
                <a href="tel:+390458012520">+39 045 801 2520</a>
              </p>
              <p className="small">
                {i18n.t("contatti_email")}:{" "}
                <a href="mailto:verona@tigiconcept.it">verona@tigiconcept.it</a>
              </p>
            </div>
            <p className={this.state.cls}>{this.state.mex}</p>

            <form onSubmit={this.sendMail} className="form-contact" id="form">
              <div className="contatti-form-content">
                <input
                  type="text"
                  placeholder={i18n.t("contatti_form_nome")}
                  maxlength="50"
                  className="form-item-name"
                  value={this.state.nome}
                  onChange={this.handleChangeName}
                  required
                />
                <input
                  type="email"
                  placeholder={i18n.t("contatti_form_email")}
                  className="form-item-mail"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  required
                />
                <input
                  type="text"
                  placeholder={i18n.t("contatti_form_telefono")}
                  maxlength="15"
                  className="form-item-tel"
                  value={this.state.telefono}
                  onChange={this.handleChangeTel}
                  required
                />
                <textarea
                  placeholder={i18n.t("contatti_form_messaggio")}
                  rows="10"
                  className="form-item-mess"
                  value={this.state.messaggio}
                  onChange={this.handleChangeMes}
                  required
                />
                <input
                  type="checkbox"
                  name="Privacy"
                  className="form-item-privacy"
                  value={this.state.privacy}
                  onChange={this.handleChangePrivacy}
                  required
                />
                <span>{i18n.t("contatti_form_privacy")} *</span>
              </div>
              <input
                type="submit"
                value={i18n.t("contatti_form_btn")}
                className="form-item-submit"
              />
              <Recaptcha
                ref={ref => (this.recaptcha = ref)}
                sitekey="6Ld_1CkUAAAAAPoGA3XJc7eGVPEQscOeMHov0uoS"
                onResolved={this.handleChangeCaptcha}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Contatti;
