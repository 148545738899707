import React, { Component } from 'react';
import ModalsVideo from '../../components/Modals/Video/';
import ModalsDefault from '../../components/Modals/Default/';
import $ from 'jquery';

import i18n from '../../assets/i18n';

class Team extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      component: '',
      data: {
        file: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/TIGI_Lapolla_720.mp4',
        image: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/lapolla_foto-team.jpg',
        repeat: 'false',
        controls: 'true',
      }
      /*team: {
        item_1: {
          img: 'team-picture team-1',
          name: i18n.t('team_item_1_name'),
          role: i18n.t('team_item_1_role'),
          description: i18n.t('team_item_1_desc'),
          video: {
            file: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/persona01.mp4',
            image: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/tigi_concept.jpg',
            repeat: 'true',
            controls: 'false',
          }
        },
        item_2: {
          img: 'team-picture team-2',
          name: i18n.t('team_item_1_name'),
          role: i18n.t('team_item_1_role'),
          description: i18n.t('team_item_1_desc'),
          video: {
            file: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/persona02.mp4',
            image: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/tigi_concept.jpg',
            repeat: 'true',
            controls: 'false',
          }
        },
        item_3: {
          img: 'team-picture team-3',
          name: i18n.t('team_item_1_name'),
          role: i18n.t('team_item_1_role'),
          description: i18n.t('team_item_1_desc'),
          video: {
            file: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/persona03.mp4',
            image: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/tigi_concept.jpg',
            repeat: 'true',
            controls: 'false',
          }
        },
      }*/
    };

    this.openBio = this.openBio.bind(this);
    this.openVideo = this.openVideo.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  click() {
    $('html, body').stop().animate({scrollTop: $('.contatti-section').offset().top}, 1000);
  }

  openBio() {
    var ModalType = <ModalsDefault title={i18n.t('team_item_boss_name')} desc={i18n.t('team_item_boss_desc')} closeModal={this.closeModal} />;
    this.setState({modal: true, component: ModalType});
  }

  openVideo() {
    var ModalType = <ModalsVideo data={this.state.data} closeModal={this.closeModal} />;
    this.setState({modal: true, component: ModalType});
  }

  closeModal(){
    this.setState({modal: false, component: ''});
  }

  render() {

    /*var obj = this.state.team;
    var that = this;
    let items = Object.keys(obj).map(function(k) {
      return (
        <TeamItem data={obj[k]} />
      )
    });
    {(this.state.modal) ? this.state.component : ''}
    <div className="team-list">{items}</div>*/

    return (
      <div className="team-section">
        <div className="animated fadeIn">
          <div className="team-boss">
            <img src="./img/lapolla_foto-team.jpg" className="foto-manager" />
            <div className="team-content">
              <h3>{i18n.t('team_item_boss_name')}</h3>
              <h5>{i18n.t('team_item_boss_role')}</h5>

              <button type="button" className="btn trasp" onClick={this.openBio}>
                <i className="icon-book"></i>{i18n.t('team_biografia')}
              </button>

              <button type="button" className="btn trasp" onClick={this.openVideo}>
                <i className="icon-video"></i>{i18n.t('team_tigi')}
              </button>

            </div>
          </div>
          {(this.state.modal) ? this.state.component : ''}
          <div className="team-list">
            <h3>{i18n.t('team_lavora_con_noi')}</h3>
            <button type="button" className="btn trasp prenota" onClick={this.click}>
              {i18n.t('curriculum')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Team;
