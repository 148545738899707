import React from 'react';
import { createRoot } from "react-dom/client";
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import './index.css';
import './assets/scss/style.scss';

// Containers
import Full from './containers/Full/'

const history = createBrowserHistory();

//<Route path="/en" name="Home EN" component={Full} />

const domNode = document.getElementById('root');
const root = createRoot(domNode)

root.render(
  <HashRouter history={history}>
    <Switch>
      <Route path="/en" name="Home EN" component={Full} />
      <Route path="/it" name="Home IT" component={Full} />
      <Redirect from="/" to="/it"/>
    </Switch>
  </HashRouter>
);
