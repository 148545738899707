import React, { Component } from 'react';

import ModalsDefault from '../../components/Modals/Default/';
import i18n from '../../assets/i18n';

class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      component: ''
    };

    this.open = this.open.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  open(e) {
   e.preventDefault();
   var iubenda_id =  i18n.t('iubenda_privacy_id');
    fetch('https://www.iubenda.com/api/privacy-policy/'+iubenda_id+'/no-markup', {method: 'GET'})
      .then((responseText) => responseText.json())
      .then((response) => {
        var ModalType = <ModalsDefault title={'Privacy policy'} desc={response.content} closeModal={this.closeModal} />;
        this.setState({modal: true, component: ModalType});
      });

  }

  closeModal(){
    this.setState({modal: false, component: ''});
  }

  render() {

    var anno = new Date().getFullYear();

    return (
      <footer className="app-footer">
        <div className="footer-1">
          <span>Seguici:</span>
          <a href="https://www.facebook.com/TigiConceptVerona/" title="TIGIConcept" target="_blank">Facebook</a>
          &nbsp;|&nbsp;<a href="https://www.instagram.com/tigiconcept_salon/" title="TIGIConcept" target="_blank">Instagram</a>
        </div>
        <div className="footer-2">
          <div className="footer-2-left"><span>Copyright © {anno}</span></div>
          <div className="footer-2-center"><span>Via Adua 6A, 37121 Verona - ITALY  -  <a href="https://www.google.it/maps/place/TIGI+CONCEPT+SALON/@45.4425829,10.9936841,15z/data=!4m2!3m1!1s0x0:0xca34b3ce39a4d758?sa=X&ved=0ahUKEwjAyKavlpPVAhUDWxQKHd0vCsUQ_BIIfDAK" target="_blank">Google Maps</a>
          &nbsp;-&nbsp;
            <a href="#" onClick={this.open}>
              Privacy policy
            </a>
            {(this.state.modal) ? this.state.component : ''}
          </span></div>
          <div className="footer-2-right"><a href="http://www.bentobox.pro/" target="_blank" title="BENTOBOX.PRO - Affamati digitali">Credits</a></div>
        </div>
      </footer>
    )
  }
}

export default Footer;
