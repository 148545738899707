import React, { Component } from 'react';
import ModalsVideo from '../../components/Modals/Video/';
import i18n from '../../assets/i18n';

class Salone extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 'DnVpbTc1qLo',
      modal: false,
      component: '',
      data: {
        file: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/TIGI_corporate_720.mp4',
        image: 'https://s3-eu-west-1.amazonaws.com/bb-video-storage-gene-0/tigi_concept/tigi_concept.jpg',
        repeat: 'false',
        controls: 'true',
      },
    };

    this.open = this.open.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  open() {
    var ModalType = <ModalsVideo data={this.state.data} closeModal={this.closeModal} />;
    this.setState({modal: true, component: ModalType});
  }

  closeModal(){
    this.setState({modal: false, component: ''});
  }

  render() {

    return (
      <div className="salone-section animated fadeIn">
        <div className="salone-content-dx">
          <div className="video-content">
            <img src="./img/video-screen.jpg" className="video-screen" />
            <button type="button" className="btn trasp" onClick={this.open}>
              {i18n.t('salone_video')}
            </button>
          </div>
        </div>
        {(this.state.modal) ? this.state.component : ''}
        <div className="salone-content-sx">
          <div className="top-content">
            <h2>{i18n.t('salone_caption')}</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default Salone;
