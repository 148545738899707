import React, { Component } from 'react';
import $ from 'jquery';

class Header extends Component {

  constructor(props){
    super(props);

    this.showHeader = this.showHeader.bind(this)
  }

  showHeader(){
    this.isVisible('.filosofia-section') ? document.body.classList.add('show-header') : document.body.classList.remove('show-header');
    this.prev = window.scrollY;
  }

  isVisible(elem) {
    var docViewTop = $(window).scrollTop();
    var elemTop = $(elem).offset().top;

    return (elemTop < docViewTop);
  }

  componentDidMount(){
    window.addEventListener('scroll', this.showHeader);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.showHeader);
  }

  render() {

    return (
      <header className='app-header'>
        <img src="./img/logo-black.png" id="logo-small" />
      </header>
    )
  }
}

export default Header;
