import React, { Component } from 'react';
import i18n from '../../assets/i18n';

class Maps extends Component {

  render() {
    return (
      <div className="maps-section">
        <div className="animated fadeIn">
          <h4 className="row-header">{i18n.t('mappa_title')}</h4>
          <div className="google-maps" id="map"></div>
        </div>
      </div>
    )
  }
}

export default Maps;
