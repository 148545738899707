import React, { Component, PropTypes } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import i18n from '../../../assets/i18n';
import $ from 'jquery';

class ModalsServices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      form: {
        type: ''
      }
    };

    this.close = this.close.bind(this);
    this.cancel = this.cancel.bind(this);

  }

  close() {
    this.setState({
      show: false
    });

    this.props.closeModal();
  }

  cancel() {
    this.close();
  }

  componentDidMount(){
    this.setState({
      show: true,
      type: this.props.type
    });
  }

  render() {

    return (
      <div className="animated fadeIn">
        <Modal isOpen={this.state.show} toggle={this.close} className={'modal-lg modal-default'}>
          <ModalHeader toggle={this.close}></ModalHeader>
          <ModalBody>
            {(this.props.type == 'serv_1') ?
              <div>
                <h4>{i18n.t('servizio_1_title')}</h4>
                <div className="services-inside-wrapper">
                  <div className="services-inside serv-1">
                    <p><strong>{i18n.t('servizio_1_cat_1')}</strong></p>
                    <p><span>{i18n.t('servizio_1_cat_1_serv_1')}</span><span>€ 65</span></p>
                    <p><span>{i18n.t('servizio_1_cat_1_serv_2')}</span><span>€ 40</span></p>
                  </div>
                  <div className="services-inside serv-2">
                    <p><strong>{i18n.t('servizio_1_cat_2')}</strong></p>
                    <p><span>{i18n.t('servizio_1_cat_2_serv_1')}</span><span>€ 75</span></p>
                    <p><span>{i18n.t('servizio_1_cat_2_serv_2')}</span><span>€ 45</span></p>
                  </div>
                  <div className="services-inside serv-3">
                    <p><strong>{i18n.t('servizio_1_cat_3')}</strong></p>
                    <p><span>{i18n.t('servizio_1_cat_3_serv_1')}</span><span>€ 85</span></p>
                    <p><span>{i18n.t('servizio_1_cat_3_serv_2')}</span><span>€ 55</span></p>
                  </div>
                  <div className="services-inside serv-4">
                    <p><span>{i18n.t('servizio_1_cat_4_serv_1')}</span><span>da € 35 a € 55</span></p>
                    <p><span>{i18n.t('servizio_1_cat_4_serv_2')}</span><span>{i18n.t('prezzo_to_def')}</span></p>
                  </div>
                </div>
              </div>
            : ''}

            {(this.props.type == 'serv_2') ?
              <div>
                <h4>{i18n.t('servizio_2_title')}</h4>
                  <div style={{ textAlign: "right"}}><i>{i18n.t('servizio_top')}</i></div>
                <div className="services-inside-wrapper">
                  <div className="serv-1">
                    <p><h6>{i18n.t('servizio_2_cat_1')}</h6></p>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_1_serv_1')}</strong><span>€ 55</span></p>
                      <p><span>{i18n.t('servizio_2_cat_1_serv_1_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_1_serv_2')}</strong><span>€ 60</span></p>
                      <p><span>{i18n.t('servizio_2_cat_1_serv_2_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_1_serv_3')}</strong><span>€ 60</span></p>
                      <p><span>{i18n.t('servizio_2_cat_1_serv_3_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_1_serv_4')}</strong><span>€ 90</span></p>
                      <p><span>{i18n.t('servizio_2_cat_1_serv_4_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_1_serv_5')}</strong><span>€ 120</span></p>
                      <p><span>{i18n.t('servizio_2_cat_1_serv_5_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_1_serv_6')}</strong><span>€ 30</span></p>
                      <p><span>{i18n.t('servizio_2_cat_1_serv_6_desc')}</span></p>
                    </div>
                  </div>
                  <div className="serv-2">
                    <p><h6>{i18n.t('servizio_2_cat_2')}</h6></p>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_2_serv_1')}</strong><span>€ 105</span></p>
                      <p><span>{i18n.t('servizio_2_cat_2_serv_1_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_2_serv_2')}</strong><span>€ 85</span></p>
                      <p><span>{i18n.t('servizio_2_cat_2_serv_2_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_2_serv_3')}</strong><span>{i18n.t('prezzo_to_def')}</span></p>
                      <p><span>{i18n.t('servizio_2_cat_2_serv_3_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_2_cat_2_serv_4')}</strong><span>€ 85</span></p>
                      <p><span>{i18n.t('servizio_2_cat_2_serv_4_desc')}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              : ''}

            {(this.props.type == 'serv_3') ?
              <div>
                <h4>{i18n.t('servizio_3_title')}</h4>
                <div className="services-inside-wrapper">
                  <div className="serv-1">
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_3_cat_1_serv_1')}</strong><span>{i18n.t('prezzo_start_of')} € 75</span></p>
                      <p><span>{i18n.t('servizio_3_cat_1_serv_1_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_3_cat_1_serv_2')}</strong><span>{i18n.t('prezzo_to_def')}</span></p>
                      <p><span>{i18n.t('servizio_3_cat_1_serv_2_desc')}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              : ''}

            {(this.props.type == 'serv_4') ?
              <div>
                <h4>{i18n.t('servizio_4_title')}</h4>
                  <div style={{ textAlign: "right"}}><i>{i18n.t('servizio_top')}</i></div>
                <div className="services-inside-wrapper">
                  <div className="serv-1">
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_4_cat_1_serv_1')}</strong><span>€ 30</span></p>
                      <p><span>{i18n.t('servizio_4_cat_1_serv_1_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_4_cat_1_serv_2')}</strong><span>€ 30</span></p>
                      <p><span>{i18n.t('servizio_4_cat_1_serv_2_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_4_cat_1_serv_3')}</strong><span>€ 30</span></p>
                      <p><span>{i18n.t('servizio_4_cat_1_serv_3_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_4_cat_1_serv_4')}</strong><span>€ 35</span></p>
                      <p><span>{i18n.t('servizio_4_cat_1_serv_4_desc')}</span></p>
                    </div>
                    <div className="services-inside">
                      <p><strong>{i18n.t('servizio_4_cat_1_serv_5')}</strong><span>€ 30</span></p>
                      <p><span>{i18n.t('servizio_4_cat_1_serv_5_desc')}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              : ''}

          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default ModalsServices;
